import { lsGet, lsSet } from "./storage.js"

export function initDarkModeCycle(reinit = false) {
  const el = document.documentElement
  const theme =
    lsGet("theme") ||
    (window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light")

  el.dataset.theme = theme
  if (!reinit) {
    el.addEventListener("click", (e) => {
      if (e.target.closest(".dark-mode")) {
        const theme = el.dataset.theme === "dark" ? "light" : "dark"
        el.dataset.theme = theme
        lsSet("theme", theme)
      }
    })
  }
}

export function initAfterDark() {
  const el = document.documentElement
  const checkbox = document.getElementById("after-dark")
  // console.log({ el, checkbox })
  if (checkbox) {
    checkbox.addEventListener("change", (e) => {
      if (e.target.checked) {
        el.dataset.theme = "dark"
      } else {
        initDarkModeCycle(true)
      }
    })
  }
}
