import { render } from "preact"
import { Game } from "./components/game"
import "./styles/index.css"

export const initFakeOrNot = () => {
  const root = document.getElementById("fake-or-not")
  if (root) {
    render(<Game />, root)
  }
}
