import { qs, qsa } from "./utils.js"

export function initFilters() {
  document.body.addEventListener("click", (e) => {
    let filter
    const target = e.target
    const type = target.dataset.type

    if (type && (filter = target.closest("[data-filter]"))) {
      const fds = filter.dataset

      e.preventDefault()

      const value = fds.show === type ? "_" : type
      fds.show = value

      filter.querySelectorAll("[data-type]").forEach((btn) => {
        btn.classList.toggle("filter__active", value === btn.dataset.type)
      })

      const nodes = qsa(`${fds.filter} [data-type]`)
      nodes.forEach((node) => {
        const types = node.dataset.type.split(",")
        node.classList.toggle("hide", value !== "_" && !types.includes(value))
      })

      window.location.hash = value === "_" ? "" : value
    }
  })

  let hash
  let button
  if (
    (hash = window.location.hash.substring(1)) &&
    (button = qs(`[data-filter] [data-type="${hash}"]`))
  ) {
    button.click()
  }
}
