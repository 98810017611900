export const Picture = ({ url, fetchData, index, showResult }) => {
  return (
    <button
      className="fake-or-not__game-button"
      type="submit"
      data-index={index}
      disabled={!!showResult}
    >
      <img
        className="fake-or-not__game-image"
        alt=""
        src={url}
        onError={(e) => {
          console.error(e)
          fetchData()
        }}
      />
    </button>
  )
}
