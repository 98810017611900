import { qs, qsa } from "./utils.js"

export function toggleMenuHandler() {
  const toggle = qs("#sl-toggle")
  const eh = qs("#expand-menu")
  const menu_menu = qs(".menu__menu")
  const menuLinks = qsa(".menu__menu a")

  if (!toggle || !eh) return

  const setState = (open) => {
    eh.checked = open
    menu_menu.setAttribute("aria-hidden", !open)
    menu_menu.setAttribute("aria-expanded", open)
    for (const item of menuLinks) {
      item.hidden = !open
    }
  }

  setState(false)

  if (toggle && eh) {
    toggle.addEventListener("click", () => {
      setState(!eh.checked)
    })

    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setState(false)
      }
    })
  }
}
